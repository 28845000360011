import { Box, Link as CLink, Center, Flex, Image, Spinner, Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import Button from 'components/atoms/Button/Button';
import Icon from 'components/atoms/Icon/Icon';
import DogsIcon from 'components/atoms/Icon/dogs';
import Layout from 'components/organisms/Layout/Layout';
import Seo from 'components/organisms/Seo/Seo';
import AuthContext from 'context/AuthProvider';
import { Link, navigate } from 'gatsby';
import { bcApi } from 'helpers/bigcommerce';
import { useParams } from 'hooks/useParams';
import React, { useContext, useEffect, useState, useMemo } from 'react';
import * as styles from './order-confirmation.module.scss';
import dayjs from 'dayjs';
import useSegment from 'hooks/useSegment';
import { getStorage, setStorage } from 'helpers/general';

const isMadPawsStore = process.env.GATSBY_STORE === 'MPS';

const OrderConfirmationPage = () => {
  const orderId = useParams('orderId');
  const auth = useContext(AuthContext);
  const { track } = useSegment();
  const [gaClientId, setGAClientId] = useState(null);
  const [gaSessionId, setGASessionId] = useState(null);
  const [gclid, setGclid] = useState(null);
  const [mscklid, setMscklid] = useState(null);
  const [segmentAnonymousId, setSegmentAnonymousId] = useState(null);
  const [isClientIdSet, setIsClientIdSet] = useState(false);
  const [isGclidSet, setIsGclidSet] = useState(false);
  const [isGASessionIdSet, setIsGASessionIdSet] = useState(false);
  const [isSegmentAnonymousIdSet, setIsSegmentAnonymousIdSet] = useState(false);
  const [isMscklidSet, setIsMscklidSet] = useState(false);
  const algoliaQueryId = getStorage('algoliaQueryId');
  const isLoggedIn = auth && auth.state?.isLoggedIn;

  useEffect(() => {
    const delay = 2000;
    const timeoutId = setTimeout(() => {
      if (typeof window !== 'undefined' && window.gtag) {
        window.gtag('get', 'G-XXXXXX', 'client_id', function(clientId) {
          setGAClientId(clientId);
          setIsClientIdSet(true);
        });

        window.gtag('get', 'G-XXXXXXXXXX', 'session_id', function(sessionId) {
          setGASessionId(sessionId);
          setIsGASessionIdSet(true);
        });

        window.gtag('get', 'G-XXXXXX', 'gclid', function(gclid) {
          setGclid(gclid);
          setIsGclidSet(true);
        });

        window.gtag('get', 'G-XXXXXX', 'mscklid', function(mscklid) {
          setMscklid(mscklid);
          setIsMscklidSet(true);
        });
      }

      if (typeof window !== 'undefined' && window.analytics) {
        const segmentId = window.analytics.user().anonymousId();
        setSegmentAnonymousId(segmentId);
        setIsSegmentAnonymousIdSet(true);
      }
    }, delay);
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const { data: order } = useQuery(
    ['order', orderId],
    async () => bcApi(`orders/${orderId}?include=consignments.line_items`, 'GET', null, 2),
    {
      enabled: !!orderId,
      refetchOnReconnect: false,
      refetchOnMount: false
    }
  );

  const { data: orderProducts } = useQuery(
    ['orderProducts', orderId],
    async () => bcApi(`orders/${orderId}/products`, 'GET', null, 2),
    {
      enabled: !!orderId,
      refetchOnReconnect: false,
      refetchOnMount: false
    }
  );

  const { data: products, isLoading } = useQuery(
    ['order_products', orderId],
    async () =>
      bcApi(
        `catalog/products?include=variants,options,images,modifiers,custom_fields&id:in=${[
          orderProducts?.response?.map(item => item.product_id).join(',')
        ].join(',')}`,
        'GET'
      ),
    {
      enabled: !!orderProducts,
      refetchOnReconnect: false,
      refetchOnMount: false
    }
  );

  const { parsedProduct, isMedication } = useMemo(() => {
    if (!products) return { parsedProduct: [], isMedication: false };
    const parsedProduct = orderProducts?.response?.map(product => {
      const findProduct = products?.response?.data?.find(item => item.id === product.product_id);

      return {
        ...product,
        details: findProduct
      };
    });
    const isMedication = parsedProduct?.some(item =>
      item.details?.custom_fields?.some(
        field => field.name === 'Product Type' && field.value === 'Medication'
      )
    );

    return { parsedProduct, isMedication };
  }, [orderProducts, products]);

  const checkStore = () => {
    if (process.env.GATSBY_STORE === 'MPS') {
      return 'mps';
    } else {
      return 'pc';
    }
  };

  useEffect(() => {
    const orderTag = async currentOrderId => {
      try {
        const store = checkStore();
        const informationString = `${gaClientId || 'NA'},${gaSessionId || 'NA'},${gclid ||
          'NA'},${mscklid || 'NA'},${store}`;
        await bcApi(
          `orders/${currentOrderId}`,
          `PUT`,
          {
            external_source: informationString
          },
          2
        );
      } catch (e) {
        console.error('Error occurred during PUT request:', e);
      }
    };
    if (orderId || isClientIdSet || isGclidSet || isGASessionIdSet || isMscklidSet || mscklid) {
      orderTag(orderId);
    }
  }, [
    isClientIdSet,
    gaClientId,
    orderId,
    isGclidSet,
    isGASessionIdSet,
    gclid,
    gaSessionId,
    isSegmentAnonymousIdSet,
    segmentAnonymousId,
    mscklid,
    isMscklidSet
  ]);

  useEffect(() => {
    if (orderId && parsedProduct?.length > 0) {
      const trackedOrders = JSON.parse(getStorage('__jamm_tracked_orders') ?? '[]');
      if (!trackedOrders.includes(orderId)) {
        trackedOrders.push(orderId);
        if (order && order.response) {
          const orderData = order.response;
          const hasMedication = parsedProduct?.some(item => {
            const customFields = item?.details?.custom_fields;
            return customFields?.some(
              field => field.name === 'Product Type' && field.value === 'Medication'
            );
          });

          const brandIdsSet = new Set();
          const categoryIdsSet = new Set();

          const productList = parsedProduct?.map(item => {
            if (item?.details?.brand_id) {
              brandIdsSet.add(item.details.brand_id);
            }
            if (item?.details?.categories) {
              item.details.categories.forEach(category => categoryIdsSet.add(category));
            }
            return {
              name: item?.details?.name,
              id: item?.id,
              product_id: item?.id,
              sku: item?.sku,
              price: Number(item?.price_inc_tax) ?? 0,
              quantity: Number(item?.quantity) ?? 0
            };
          });

          track('Order Completed', {
            affiliation: isMadPawsStore ? 'MPS' : 'PC',
            orderId: Number(orderId) ?? 0,
            revenue: Number(orderData.total_inc_tax) ?? 0,
            discount: Number(orderData.discount_amount) ?? 0,
            tax: Number(orderData.total_tax) ?? 0,
            algolia_query_id: algoliaQueryId,
            shipping: Number(orderData.shipping_cost_inc_tax) ?? 0,
            search_index: process.env.GATSBY_ALGOLIA_INDEX_NAME,
            brand_ids: Array.from(brandIdsSet),
            category_ids: Array.from(categoryIdsSet),
            billing_address: {
              postcode: Number(orderData.billing_address.zip) ?? orderData.billing_address.zip,
              state: orderData.billing_address.state,
              phone: orderData.billing_address.phone
            },
            products: productList,
            product_type: !!hasMedication ? 'Medication' : 'OTC'
          });
        }
        setStorage('__jamm_tracked_orders', JSON.stringify(trackedOrders));
      }

      // Define the renderOptIn function
      if (!document.getElementById('surver-optin') && order && orderId) {
        const script = document.createElement('script');
        script.src = 'https://apis.google.com/js/platform.js?onload=renderOptIn';
        script.async = true;
        script.defer = true;
        script.id = 'surver-optin';
        document.body.appendChild(script);

        // const orderProducts = [];
        // parsedProduct?.forEach(product => {
        //     orderProducts.push({
        //         "gtin": `${product.id}`
        //     });
        // });
        // const newOrderProducts = JSON.stringify(orderProducts);

        const optinScript = document.createElement('script');
        optinScript.innerHTML = `window.renderOptIn = function() {
          window.gapi.load('surveyoptin', function() {
            window.gapi.surveyoptin.render({
              "merchant_id": 111416146,
              "order_id": "${orderId}",
              "email": "${order?.response?.billing_address?.email}",
              "delivery_country": "AU",
              "opt_in_style": "CENTER_DIALOG",
              "estimated_delivery_date": "${dayjs()
                .add(3, 'day')
                .format('YYYY-MM-DD')}"
            });
          });
        };`;
        document.body.appendChild(optinScript);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, parsedProduct, order]);

  const cognitoFormsLink = () => {
    let cognitoLink =
      'https://www.cognitoforms.com/madpaws1/medicationorderprescriptiondetailscollection';
    if (order && order.status === 200 && order.response) {
      const dataPrefill = {
        YourDetails: {
          Name: {
            First: order.response.billing_address?.first_name,
            Last: order.response.billing_address?.last_name
          },
          OrderNumber: order.response.id
        }
      };
      cognitoLink = `${cognitoLink}?entry=${encodeURIComponent(JSON.stringify(dataPrefill))}`;
    }
    return cognitoLink;
  };

  return (
    <Layout slim={true} className={styles.root}>
      <Seo title="Order confirmation" />
      <Flex className="order-confirmation-container">
        <Box className="order-details">
          <Text fontSize={18} fontWeight={'bold'} color="#202020">
            Thank you for you order
          </Text>

          <Text className="slate" mt={6} mb={4}>
            Your order number is <strong>{orderId}</strong>.
          </Text>
          <Text className="slate">
            An email will be sent containing information about your purchase. If you have any
            questions about your purchase, please visit our&nbsp;
            <CLink color={'#14607A'} href="https://petchemist.brainfish.ai/" target="_blank">
              Help Centre
            </CLink>
            .
          </Text>

          <CLink
            fontSize={15}
            mt={10}
            gap={2}
            as={Link}
            to={'/'}
            display={'flex'}
            alignItems={'center'}
            color={'#14607A'}
          >
            Continue Shopping
            <Icon symbol={'chevRight'} />
          </CLink>
          <Box bg={'#F3F2F7'}></Box>

          {!isLoggedIn && (
            <Box className="order-instructions" mt={10}>
              <Stack spacing={'23px'}>
                <Text>
                  <strong>Create an account with us and you’ll be able to:</strong>
                </Text>
                <ul>
                  <li>Check out quickly every time</li>
                  <li>Track your orders</li>
                  <li>Manage any subscriptions</li>
                  <li>Receive weekly discounts and coupons</li>
                </ul>
                <Button
                  className={styles.createAccount}
                  level={'tertiary'}
                  type={'button'}
                  onClick={() => navigate('/signup')}
                >
                  CREATE ACCOUNT
                </Button>
              </Stack>
            </Box>
          )}

          {isMedication && (
            <>
              <Box className="dogs" px={2}>
                <DogsIcon />
              </Box>
              <Box className="order-instructions">
                <Flex gap={4}>
                  <Icon symbol={'prescription'} />

                  <Stack spacing={2}>
                    <Text color={'var(--red-04)'}>
                      <strong>Prescription Information Required</strong>
                    </Text>
                    <Text className="slate">
                      Please click on the link below to upload your prescription and provide details
                      about your pet.
                    </Text>

                    <Box>
                      <Button
                        className={styles.createAccount}
                        level={'tertiary'}
                        target
                        type={'a'}
                        href={cognitoFormsLink()}
                      >
                        ADD INFORMATION
                      </Button>
                      <Text mt={2} fontSize={12} color={'#14607A'} textAlign={'center'} maxW={176}>
                        Takes approx 2 minutes
                      </Text>
                    </Box>
                  </Stack>
                </Flex>
              </Box>
            </>
          )}
        </Box>
        <div className="order-summary-container">
          <Box p={6} flexShrink={0} className="order-summary">
            <Text mb={6} fontSize={18} fontWeight={'bold'} color="#202020">
              Order Summary
            </Text>
            <hr />

            {isLoading ? (
              <Center minH={250}>
                <Spinner size={'xl'} color="teal" />
              </Center>
            ) : (
              <>
                <Box>
                  {parsedProduct?.map((item, index) => (
                    <React.Fragment key={index}>
                      <Flex justifyContent={'space-between'} gap={4} pb={6}>
                        <Flex gap={4} align={'center'}>
                          <Box w={'54px'} flexShrink={0}>
                            <Image
                              m={0}
                              objectFit={'contain'}
                              src={
                                item.details?.images?.find(image => image.is_thumbnail)
                                  ?.url_thumbnail ?? '/404.svg'
                              }
                              alt={item.details?.name}
                            />
                          </Box>
                          <Box>
                            <Text fontSize={14} noOfLines={2}>
                              {item.name}
                            </Text>
                            <Text className="slate">Qty: {item.quantity}</Text>
                          </Box>
                        </Flex>
                        <Text fontSize={14}>${Number(item?.total_ex_tax ?? 0).toFixed(2)}</Text>
                      </Flex>
                      <hr />
                    </React.Fragment>
                  ))}
                </Box>
                <Stack spacing={'19px'}>
                  <div className="price-line">
                    <span className="label">Subtotal</span>
                    <span className="value">
                      ${Number(order?.response?.subtotal_ex_tax ?? 0).toFixed(2)}
                    </span>
                  </div>
                  <div className="price-line">
                    <span className="label">Shipping</span>
                    <span className="value">
                      ${Number(order?.response?.shipping_cost_ex_tax ?? 0).toFixed(2)}
                    </span>
                  </div>
                  <div className="price-line">
                    <span className="label">GST</span>
                    <span className="value">
                      ${Number(order?.response?.total_tax ?? 0).toFixed(2)}
                    </span>
                  </div>
                </Stack>

                <Box as="hr" my={6} />
                <Flex fontSize={18} fontWeight={'bold'} justifyContent={'space-between'}>
                  <span className="label">Total ({order?.response?.default_currency_code})</span>
                  <span className="value">
                    ${Number(order?.response?.total_inc_tax ?? 0).toFixed(2)}
                  </span>
                </Flex>
              </>
            )}
          </Box>
        </div>
      </Flex>
    </Layout>
  );
};

export default OrderConfirmationPage;
